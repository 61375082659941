import React from 'react';
import styled from 'styled-components';
import jobArrowIcon from '../../assets/icon/job-icon.svg';
import NewTabLink from './NewTabLink';
import breakpoints from '../../utils/breakpoints';

const JobItem = ({ job }) => (
  <NewTabLink href={job.url}>
    <Wrapper>
      <TitleWrapper>
        <Title>{job.title}</Title>
        <Label>{job.label}</Label>
      </TitleWrapper>
      <TagWrapper>
        {job.tags.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </TagWrapper>
      <DescriptionWrapper>
        <Description>{job.description}</Description>
        <ArrowIcon src={jobArrowIcon} />
      </DescriptionWrapper>
    </Wrapper>
  </NewTabLink>
);

export default JobItem;

const ArrowIcon = styled.img`
  display: none;
  ${breakpoints.mobile} {
    display: block;
  }
`;

const Wrapper = styled.div`
  border-top: 1px solid #edf2f4;
  padding: 16px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 7px 24px rgba(194, 209, 217, 0.5);
    border-radius: 8px;
    transition: all 300ms ease;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #8698a4;
  display: none;

  ${breakpoints.mobile} {
    display: block;
  }
`;

const Tag = styled.div`
  background: #8c96f3;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
`;

const TagWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #8698a4;
  max-width: 600px;
`;
