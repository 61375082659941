import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from '../theme';
import GlobalStyles from '../GlobalStyles';
import Navbar from '../components/Navbar';

import Footer from '../components/Footer';
import Helmet from '../components/Layout';
import HeroJoin from '../components/HeroJoin';
import PeopleSliderSection from '../components/PeopleSliderSection';
import JobListSection from '../components/JobListSection';
import JoinUsFooterSection from '../components/JoinUsFooterSection';
import UpArrow from '../components/UpArrow';
import TeamGallery from '../components/TeamGallery';
//import BlogSection from '../components/BlogSection';

const Career = () => {
  useEffect(() => {
    if (typeof window === 'object') {
      return window.scrollTo(0, 0);
    }
  }, []);
  return (
    <ThemeProvider theme={lightTheme}>
      <Helmet />
      <GlobalStyles />
      <>
        <Navbar />
        <HeroJoin />
        <PeopleSliderSection />
        <JobListSection />
        {/* <BlogSection /> */}
        <TeamGallery />
        <JoinUsFooterSection />
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Career;
