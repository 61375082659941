import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useIntl } from 'gatsby-plugin-intl';

import { Section, NewTabLink } from './common';
import peopleSliderImage from '../assets/people-slider-image.jpg';
import linkedInLogo from '../assets/linkedin_logo.svg';
import rightArrowIcon from '../assets/icon/ic_chevron-right.svg';
import leftArrowIcon from '../assets/icon/ic_chevron-left.svg';

import peopleCs from '../data/people';
import peopleEn from '../data/people-en';

import breakpoints from '../utils/breakpoints';

export const sizes = {
  XXS: 320,
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

const getPreviousImage = currentSlide => {
  if (currentSlide === 0) return 5;

  return currentSlide - 1;
};

const getNextImage = currentSlide => {
  if (currentSlide === 5) return 0;
  return currentSlide + 1;
};

const getNext2Image = currentSlide => {
  if (currentSlide === 4) return 0;
  if (currentSlide === 5) return 1;
  if (currentSlide === 6) return 2;

  return currentSlide + 2;
};

const PrevArrow = props => (
  <ArrowCircle onClick={props.onClick}>
    <img src={leftArrowIcon} alt="arrow" />
  </ArrowCircle>
);

const NextArrow = props => (
  <ArrowCircle onClick={props.onClick} style={{ left: 308 }}>
    <img src={rightArrowIcon} alt="arrow" />
  </ArrowCircle>
);

const defaultSettings = {
  speed: 200,
  dots: false,
  draggable: true,
  arrows: true,
  slidesToShow: 1,
  initialSlide: 0,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  verticalScrolling: false,
  // autoplaySpeed: 5000,
  fade: true,
  // autoplay: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: sizes.XL,
      settings: {
        slidesToScroll: 3,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: sizes.LG,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: sizes.MD,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1.4,
      },
    },
    {
      breakpoint: sizes.SM,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1.2,
      },
    },
  ],
};

const PeopleSliderSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intl = useIntl();
  const { locale } = intl;

  const sliderRef = useRef(null);
  const goToSlide = e => sliderRef.current.slickGoTo(e.target.id);

  const people = locale === 'cs' ? peopleCs : peopleEn;

  return (
    <Section>
      <Wrapper>
        <RightSide>
          <Slider
            afterChange={setCurrentSlide}
            {...defaultSettings}
            ref={sliderRef}
          >
            {people.map((person, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={person.title + i}>
                <SlideWrapper>
                  <SmallProfilesWrapper>
                    <SliderProfileImage
                      src={people[getPreviousImage(currentSlide)].imageUrl}
                      id={people[getPreviousImage(currentSlide)].id}
                      onClick={goToSlide}
                    />
                    <CurrentSliderProfileImage
                      src={people[currentSlide].imageUrl}
                    />

                    <SliderProfileImage
                      src={people[getNextImage(currentSlide)].imageUrl}
                      id={people[getNextImage(currentSlide)].id}
                      onClick={goToSlide}
                    />
                    <SliderProfileImage
                      src={people[getNext2Image(currentSlide)].imageUrl}
                      id={people[getNext2Image(currentSlide)].id}
                      onClick={goToSlide}
                    />
                  </SmallProfilesWrapper>
                  <TitleWrapper>
                    <NewTabLink href={people[currentSlide].linkedInUrl}>
                      <NameWrapper>
                        <Title>{person.title}</Title>
                        <img src={linkedInLogo} alt="linkedin-logo" />
                      </NameWrapper>
                    </NewTabLink>
                    <SubTitle>{person.position}</SubTitle>
                    <Description>{person.longDescription}</Description>
                  </TitleWrapper>
                </SlideWrapper>
              </div>
            ))}
          </Slider>
        </RightSide>

        <LeftSide>
          <Image src={peopleSliderImage} />
        </LeftSide>
      </Wrapper>
    </Section>
  );
};

const SlideWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

export default PeopleSliderSection;

const SliderProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
`;

const CurrentSliderProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const SmallProfilesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 64px;
  margin-bottom: 16px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ArrowCircle = styled.div`
  background: #ae99e1;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  z-index: 100;

  width: 48px;
  height: 48px;

  &:hover {
    background: #4b3bc0;
    transition: all 200ms ease;
  }
`;

const LeftSide = styled.div`
  display: none;

  ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
  }

  ${breakpoints.tablet} {
    display: block;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
`;

const Description = styled.div`
  margin-top: 32px;
  font-size: 16px;
  line-height: 25px;
  color: #8698a4;
`;

const Image = styled.img`
  max-width: 500px;
  width: 100%;
`;

const RightSide = styled.div`
  .slick-slider {
    max-width: 500px;
    margin: 0 auto;
    position: relative;
  }
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  ${breakpoints.tablet} {
    max-width: 600px;
  }
`;

const Wrapper = styled.div`
  display: block;

  ${breakpoints.tablet} {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 50%;
    }
  }
`;

const Title = styled.div`
  font-size: 50px;
  line-height: 52px;
  font-weight: bold;
  margin-bottom: 14px;
  margin-right: 32px;
  word-spacing: 9999999px;

  ${breakpoints.mobile} {
    word-spacing: 0;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 62px;

  ${breakpoints.tablet} {
    margin-right: 32px;
  }
`;

const SubTitle = styled.div`
  font-size: 24px;
  line-height: 29px;
`;
