import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

import { Section } from './common';

import JobItem from './common/JobItem';
import TitleHeader from './TitleHeader';
import jobsCs from '../data/jobs.json';
import jobsEn from '../data/jobs-en.json';

const JobListSection = () => {
  const intl = useIntl();
  const { locale } = intl;

  const jobs = locale === 'cs' ? jobsCs : jobsEn;
  return (
    <Section id="job-list">
      <TitleHeader
        titleKey="Career_JobList_title"
        subtitle="Career_JobList_subtitle"
        center
      />
      <JobList>
        {jobs.map(job => (
          <JobItem job={job} key={job.title} />
        ))}
      </JobList>
    </Section>
  );
};

export default JobListSection;

const JobList = styled.div`
  max-width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #edf2f4;
`;
